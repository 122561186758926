export default {
    init() {
        // JavaScript to be fired on all pages

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('.slick-slider').css('opacity', '1');

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
